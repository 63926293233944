import React, { useState } from 'react'
import { Link } from '@reach/router'
import { navigate } from 'gatsby'
import styled from 'styled-components'
import { useApiLoad } from '../shared/hooks'
import { TextFormGroup } from '../shared/fields'
import * as API from '../../services/api'
import { helpCmsArticlePath } from '../../utils/paths'

const Container = styled.div`
  text-align: center;
  padding-top: 64px;

  ul {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    max-width: 1000px;
    padding: 0;
    margin: 0 auto;
    margin-top: 42px;
    list-style: none;

    li {
      a {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 200px;
        height: 80px;
        margin: 16px 25px;
        color: #444;
        background: white;
        border: 2px solid #dde1e5;
        font-family: inherit;
        font-size: 14px;
        font-weight: 600;
        cursor: pointer;
        transition: box-shadow 0.3s ease-in-out;

        &:hover {
          box-shadow: 0 2px 8px -2px #cacaca;
        }
      }
    }
  }

  .custom-cms {
    margin-top: 48px;

    .cms-custom-show {
      color: inherit;
      font-family: inherit;
      font-weight: inherit;

      &:hover,
      &.clicked {
        text-decoration: underline;
      }
    }

    .custom-cms-input-container {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: space-between;
      max-width: 640px;
      padding: 0;
      margin: 32px auto 0;
      text-align: left;
    }

    .form-group {
      flex: 1;
      margin: 0;
    }

    input {
      width: 100%;
      border: 1px solid #dde1e5;
      background: #fff;
    }

    .cms-custom-submit {
      height: 44px;
      padding-left: 24px;
      padding-right: 24px;
      margin-left: 32px;
    }
  }
`

export default function CmsMinorArticle(props) {
  const [articles, setArticles] = useState(null)

  const [showCmsCustom, setShowCmsCustom] = useState(false)
  const [cmsCustom, setCmsCustom] = useState('')
  const [isSubmitting, setIsSubmitting] = useState(false)

  useApiLoad(
    '/signup/articles/cms?platforms=minor',
    articles === null,
    (response) => {
      setArticles(response?.data?.articles)
    }
  )

  const submitCmsCustom = () => {
    if (isSubmitting || !cmsCustom) {
      return
    }

    setIsSubmitting(true)
    API.putRequest('/signup/articles/cms', { cmsCustom })
      .then((response) => {
        setIsSubmitting(false)
        if (response?.data?.success) {
          navigate(helpCmsArticlePath('custom'))
        }
      })
      .catch((error) => {
        setIsSubmitting(false)
        console.error(error)
      })
  }

  return (
    <Container>
      <h4> Which platform do you use?</h4>
      <p className="small">Let's get you up and running</p>

      {articles && (
        <>
          <ul>
            {articles.map((article) => (
              <li key={article.uuid}>
                <Link
                  to={helpCmsArticlePath(
                    encodeURIComponent(article.slug.toLowerCase())
                  )}
                >
                  <div>{article.slug}</div>
                </Link>
              </li>
            ))}
          </ul>
          <div className="custom-cms">
            <button
              className={`cms-custom-show a small ${
                showCmsCustom ? 'clicked' : ''
              }`}
              onClick={(e) => {
                e.preventDefault()
                setShowCmsCustom(true)
              }}
            >
              My platform is not on the list
            </button>
            {showCmsCustom && (
              <div className="custom-cms-input-container">
                <TextFormGroup>
                  <input
                    id="data_cms_custom"
                    name="data.cmsCustom"
                    type="text"
                    className={cmsCustom ? 'has-value' : ''}
                    value={cmsCustom}
                    onChange={(e) => setCmsCustom(e.target.value)}
                  />
                  <label htmlFor="data_cms_custom">
                    Please tell us which platform you use
                  </label>
                </TextFormGroup>
                <button
                  className="cms-custom-submit btn btn-secondary"
                  onClick={submitCmsCustom}
                  disabled={isSubmitting}
                >
                  Continue
                </button>
              </div>
            )}
          </div>
        </>
      )}
    </Container>
  )
}
