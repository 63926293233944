import React, { useState, useEffect } from 'react'
import { navigate } from 'gatsby'
import styled from 'styled-components'
import { compact } from 'lodash'
import { rootPath } from '../../utils/paths'
import * as API from '../../services/api'
import { getCurrentUser } from '../../services/auth'

const Container = styled.div`
  max-width: 670px;
  padding-top: 150px;
  padding-bottom: 50px;
  margin: 0 auto;

  fieldset {
    margin: 32px 0;

    label {
      margin-bottom: 8px;
      font-weight: bold;
    }

    button {
      padding-left: 0;
      padding-right: 0;
      margin-left: 16px;
      font-family: inherit;
      font-size: 14px;
      font-weight: 400;
      text-decoration: none;
    }
  }
`

export default function SendInstructions(props) {
  const cms = props.cms || ''

  const [emails, setEmails] = useState([''])
  const [isSubmitting, setIsSubmitting] = useState(false)

  const [currentUser, setCurrentUser] = useState(null)

  useEffect(() => {
    if (currentUser === null) {
      const user = getCurrentUser()
      setCurrentUser(user)

      if (user?.email) {
        setEmails([user.email, ...emails])
      }
    }
  }, [currentUser, emails, setEmails])

  const setEmail = (i) => (event) => {
    const newEmails = [...emails]
    newEmails[i] = event.target.value
    setEmails(newEmails)
  }

  const addEmail = () => {
    setEmails([...emails, ''])
  }

  const removeEmail = (i) => () => {
    const newEmails = [...emails]
    newEmails.splice(i, 1)
    setEmails(newEmails)
  }

  const validate = (submit) => () => {
    let invalidIndex = -1
    for (const i in emails) {
      if (!emails[i].match(/^[^@\s]+@[^@\s]+\..*$/)) {
        invalidIndex = i
        break
      }
    }
    if (invalidIndex === -1) {
      submit()
    } else {
      document.querySelectorAll('fieldset input')[invalidIndex].focus()
    }
  }

  const submit = () => {
    if (isSubmitting || !emails || compact(emails).length === 0) {
      return
    }

    setIsSubmitting(true)
    API.postRequest('/signup/advertisers/share', { cms, emails })
      .then((response) => {
        setIsSubmitting(false)
        navigate(rootPath())
      })
      .catch((error) => {
        setIsSubmitting(false)
        console.error(error)
      })
  }

  return (
    <Container>
      <h3>Send setup instructions</h3>
      <div>
        Wanna let your developers take care of the setup or save it for later?
      </div>
      <div>Add one or more emails below to send instructions</div>
      <fieldset>
        {emails.map((value, i) => (
          <React.Fragment key={i}>
            <label htmlFor={`send_email_${i}`}>Email address</label>
            <input
              id={`send_email_${i}`}
              type="email"
              className="form-control"
              value={value}
              onChange={setEmail(i)}
            />
            <div className="text-right">
              {i === emails.length - 1 && (
                <button onClick={addEmail} className="btn btn-link">
                  Add another email
                </button>
              )}
              {emails.length >= 2 && (
                <button
                  onClick={removeEmail(i)}
                  className="btn btn-link text-danger"
                >
                  Remove email
                </button>
              )}
            </div>
          </React.Fragment>
        ))}
      </fieldset>
      <button
        className="btn btn-secondary"
        onClick={validate(submit)}
        disabled={isSubmitting}
      >
        Send and go to login
      </button>
    </Container>
  )
}
