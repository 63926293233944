import React from 'react'
import { Link } from '@reach/router'
import { navigate } from 'gatsby'
import SVG from 'react-inlinesvg'
import {
  rootPath,
  helpMajorArticlesPath,
  helpInstructionsPath,
} from '../../utils/paths'

export default function CmsArticle(props) {
  const { article } = props

  const cms = article.slug

  const articleBody = article?.body
    .replace(
      /<\/code>(<\/pre>)?/g,
      `</code>$1<button class='primary click-to-copy'>Copy</button>` +
        `<a href='${helpInstructionsPath(
          cms
        )}' class='secondary send-instructions'>Email installation instructions</a>`
    )
    .replace(/SCRIPTMANAGER_URL/g, article?.scriptmanagerUrl)

  const onClick = (event) => {
    const target = event.target

    if (!target) {
      return
    }

    if (target.classList.contains('click-to-copy')) {
      const code =
        target.closest('p')?.querySelector('code') ||
        target.previousSibling?.querySelector('code')
      if (code) {
        const selection = window.getSelection()
        const range = document.createRange()

        range.selectNode(code)
        selection.removeAllRanges()
        selection.addRange(range)

        document.execCommand('copy')

        selection.removeAllRanges()

        const initialButtonText = target.innerText
        event.target.innerText = 'Copied'
        setTimeout(() => {
          target.innerText = initialButtonText
        }, 3000)
      }
    }
  }

  const onDone = () => {
    // TODO: Here we need to make sure we will show a popup on navigate
    navigate(rootPath())
  }

  return (
    <>
      <aside>
        <div className="connection-test-info">
          <div className="heading">Are you done?</div>
          <p>Let's get you logged into the dashboard.</p>
          <button onClick={onDone} className="btn primary">
            Yes, I'm done
          </button>
          <Link to={helpInstructionsPath(cms)} className="btn">
            No, finish setup later
          </Link>
        </div>
      </aside>
      <Link to={helpMajorArticlesPath()} className="all-articles-link">
        <SVG src="/images/icons/arrow-left.svg" height={12} />
        Choose a platform
      </Link>
      <article
        className={article.category}
        onClick={onClick}
        onKeyDown={() => {}}
        role="presentation"
      >
        <h1>{article.title}</h1>
        <h3>{article.subtitle}</h3>
        <div dangerouslySetInnerHTML={{ __html: articleBody }} />
        <br />
        <button className="primary" onClick={onDone}>
          I'm done
        </button>
      </article>
    </>
  )
}
