import React from 'react'
import { Router } from '@reach/router'
import Layout from '../components/help'
import CmsMajorArticles from '../components/help/CmsMajorArticles'
import CmsMinorArticles from '../components/help/CmsMinorArticles'
import Article from '../components/help/Article'
import SendInstructions from '../components/help/SendInstructions'
import { Head } from '../components/shared/elements'
import {
  helpMajorArticlesPath,
  helpMinorArticlesPath,
  helpCmsArticlePath,
  helpInstructionsPath,
  helpArticlePath,
} from '../utils/paths'

export default (props) => {
  return (
    <>
      <Head />
      <Layout {...props}>
        <Router>
          <CmsMajorArticles path={helpMajorArticlesPath()} />
          <CmsMinorArticles path={helpMinorArticlesPath()} />
          <Article category="cms" path={helpCmsArticlePath(':slug')} />
          <Article category="help" path={helpArticlePath('*')} />
          <SendInstructions path={helpInstructionsPath('')} />
          <SendInstructions path={helpInstructionsPath(':cms')} />
        </Router>
      </Layout>
    </>
  )
}
