import React, { useState } from 'react'
import styled from 'styled-components'
import CmsArticle from './CmsArticle'
import HelpArticle from './HelpArticle'
import { headerHeight } from '../shared/elements'
import { useApiLoad } from '../shared/hooks'

const asideWidth = 400

const Container = styled.div`
  position: relative;
  padding: 32px;

  aside {
    position: fixed;
    top: ${headerHeight}px;
    right: 0;
    bottom: 0;
    width: ${asideWidth}px;
    padding: 100px 50px 50px;
    color: #c2c9d9;
    background: #2c3342;
    text-align: center;
    overflow-y: auto;

    .connection-test-info {
      .heading {
        font-size: 20px;
        font-weight: 500;
        margin-bottom: 18px;
      }

      p {
        margin-bottom: 24px;
        font-weight: 400;
      }

      .btn {
        width: 220px;
        padding: 10px 0;
        margin-bottom: 24px;
        border: none;
        border-radius: 4px;
        color: white;
        background: #636b81;
        font-size: 16px;
        font-weight: normal;
        white-space: nowrap;
        transition: background 0.3s ease-in-out;

        &:hover {
          background: #7b849c;
        }

        &.primary {
          color: #5c5739;
          background: #ffc106;

          &:hover {
            background: #ffcb30;
          }
        }
      }
    }

    .connection-test-result {
      padding: 12px;
      border: 2px solid #636b81;

      .heading {
        font-size: 16px;
        font-weight: 500;
      }

      p {
        margin: 20px 0 0;
      }

      .btn {
        display: inline-block;
        padding: 10px 40px;
        border: none;
        border-radius: 4px;
        color: white;
        background: #636b81;
        font-weight: 400;
      }

      a {
        display: block;
        font-family: inherit;
        text-decoration: none;
        color: inherit;
        transition: color 0.3s ease-in-out;

        &:hover {
          color: #e9eef9;
        }
      }
    }
  }

  .all-articles-link {
    display: block;
    color: #888;
    font-family: inherit;
    font-weight: 400;
    margin-bottom: 64px;

    svg {
      position: relative;
      top: -2px;
      margin-right: 4px;
    }
  }

  article {
    padding: 32px;

    &.cms {
      width: calc(100% - ${asideWidth}px);
    }

    &.help {
      width: 100%;
      max-width: 800px;
      margin: 0 auto;
    }

    .all-articles-link {
      margin-top: -32px;
      margin-left: -32px;
    }

    h3 {
      margin-bottom: 6px;
    }

    p {
      margin-top: 16px;
      margin-bottom: 16px;
    }

    img {
      max-width: 100%;
      height: auto;
    }

    li + li {
      margin-top: 32px;
    }

    table {
      tr + tr {
        border-top: 1px solid var(--text-light);
      }
    }

    a,
    button {
      display: inline-block;
      margin-top: 0;
      margin-right: 16px;
      border-radius: 3px;
      font-family: inherit;
      font-weight: normal;
      transition: background 0.3s ease-in-out;

      &.primary {
        padding: 8px 20px;
        border: none;
        color: #5c5739;
        background: #ffc106;

        &:hover {
          background: #ffcb30;
        }
      }

      &.secondary {
        padding: 7px 19px;
        color: #63615a;
        border: 1px solid #b3bcc4;
        background: #fff;

        &:hover {
          background: #eee;
        }
      }

      &.click-to-copy {
        width: 90px;
        margin-top: 8px;
      }
    }

    pre {
      margin: 0;
    }

    code {
      display: block;
      min-width: 100%;
      padding: 4px 8px;
      background: #fff;
      overflow-x: scroll;
      white-space: nowrap;
    }

    ol li {
      margin-top: 6px !important;
    }

    ul li {
      margin-top: 6px !important;
    }
  }
`

export default function Article(props) {
  const { category } = props

  const slug = props.slug || props['*']

  const [article, setArticle] = useState(null)

  useApiLoad(
    `/signup/articles/${category}/${encodeURIComponent(slug.toLowerCase())}`,
    article === null,
    (response) => {
      setArticle(response?.data?.article)
    }
  )

  if (!article) {
    return <Container />
  }

  if (article.category === 'cms') {
    return (
      <Container>
        <CmsArticle article={article} />
      </Container>
    )
  }

  if (article.category === 'help') {
    return (
      <Container>
        <HelpArticle article={article} />
      </Container>
    )
  }
}
