import React, { useState, useEffect, createContext } from 'react'
import { navigate } from 'gatsby'
import { parse } from 'query-string'
import Layout from './Layout'
import { useApiLoad } from '../shared/hooks'
import { setContractCode, getContractCode } from '../../services/auth'

export const context = createContext()

export default function Help(props) {
  const query = parse(props.location.search)

  const [codeChecked, setCodeChecked] = useState(false)
  const [code, setCode] = useState(null)
  const [contract, setContract] = useState(null)

  useEffect(() => {
    const newCode = query.code || getContractCode()
    if (newCode) {
      if (newCode !== code) {
        setCode(newCode)
      }
    } else {
      setCodeChecked(true)
    }
  }, [query.code, code, codeChecked])

  useApiLoad(
    `/signup/contracts/${encodeURIComponent(code)}`,
    code && contract === null,
    (response) => {
      const newContract = response?.data?.contract
      if (newContract) {
        setContractCode(newContract.code)
        setContract(newContract)
        setCodeChecked(true)
      } else {
        navigate('/')
      }
    }
  )

  return (
    <Layout>
      <context.Provider value={{ codeChecked, setCodeChecked, contract }}>
        {props.children}
      </context.Provider>
    </Layout>
  )
}
