import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { Header, headerHeight } from '../shared/elements'
import { isHeaderlessLayout } from '../../utils/helpers'

import 'bootstrap/dist/css/bootstrap.min.css'
import '../../../static/stylesheets/bundle.scss'

const Main = styled.main`
  position: relative;
  padding: 42px 24px;

  &:not(.headerless) {
    margin-top: ${headerHeight}px;
  }
`

export default (props) => {
  const [isHeaderless, setHeaderless] = useState(null)

  useEffect(() => {
    setHeaderless(isHeaderlessLayout())
  }, [])

  const mainClass = isHeaderless ? 'headerless' : ''

  return (
    <div>
      <Header />
      <Main className={mainClass}>{props.children}</Main>
    </div>
  )
}
