import React, { useState } from 'react'
import { Link } from '@reach/router'
import styled from 'styled-components'
import SVG from 'react-inlinesvg'
import { useApiLoad } from '../shared/hooks'
import { helpCmsArticlePath, helpMinorArticlesPath } from '../../utils/paths'

const Container = styled.div`
  text-align: center;
  padding-top: 64px;

  ul {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    max-width: 1000px;
    padding: 0;
    margin: 0 auto;
    margin-top: 42px;
    list-style: none;

    li {
      a {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        width: 200px;
        height: 200px;
        margin: 16px 25px;
        color: #444;
        background: white;
        border: 2px solid #dde1e5;
        font-size: 12px;
        font-family: inherit;
        font-weight: 600;
        cursor: pointer;
        transition: box-shadow 0.3s ease-in-out;

        img {
          width: 80px;
          height: 80px;
          margin-top: 30px;
          margin-bottom: 30px;
          object-fit: contain;
        }

        svg {
          width: 80px;
          height: 80px;
          margin-top: 30px;
          margin-bottom: 30px;
        }

        &:hover {
          box-shadow: 0 2px 8px -2px #cacaca;
        }
      }
    }
  }
`

export default function CmsMajorArticle(props) {
  const [articles, setArticles] = useState(null)

  useApiLoad('/signup/articles/cms', articles === null, (response) => {
    setArticles(response?.data?.articles)
  })

  return (
    <Container>
      <h4>Which platform do you use?</h4>
      <p className="small">Let's get you up and running</p>

      {articles && (
        <ul>
          {articles.map((article) => (
            <li key={article.uuid}>
              <Link
                to={helpCmsArticlePath(
                  encodeURIComponent(article.slug.toLowerCase())
                )}
              >
                <img src={article.logoUrl} alt={article.slug} />
                <div>{article.slug}</div>
              </Link>
            </li>
          ))}
          <li>
            <Link to={helpMinorArticlesPath()}>
              <SVG src="/images/signup/articles-other-platforms.svg" />
              <div>Another Platform</div>
            </Link>
          </li>
          <li>
            <Link to={helpCmsArticlePath('not-sure')}>
              <SVG src="/images/signup/articles-not-sure.svg" />
              <div>I'm Not Sure</div>
            </Link>
          </li>
        </ul>
      )}
    </Container>
  )
}
