import React from 'react'
import { navigate } from 'gatsby'
import SVG from 'react-inlinesvg'
import { rootPath } from '../../utils/paths'

export default function HelpArticle(props) {
  const { article } = props

  const articleBody = article?.body.replace(
    /<\/code>(<\/pre>)?/g,
    `</code>$1<button class='primary click-to-copy'>Copy</button>`
  )

  const onClick = (event) => {
    const target = event.target

    if (!target) {
      return
    }

    if (target.classList.contains('click-to-copy')) {
      const code =
        target.closest('p')?.querySelector('code') ||
        target.previousSibling?.querySelector('code')
      if (code) {
        const selection = window.getSelection()
        const range = document.createRange()

        range.selectNode(code)
        selection.removeAllRanges()
        selection.addRange(range)

        document.execCommand('copy')

        selection.removeAllRanges()

        const initialButtonText = target.innerText
        event.target.innerText = 'Copied'
        setTimeout(() => {
          target.innerText = initialButtonText
        }, 3000)
      }
    }
  }

  const onDone = () => {
    if (document.referrer) {
      navigate(-1)
    } else {
      navigate(rootPath())
    }
  }

  return (
    <>
      <article
        className={article.category}
        onClick={onClick}
        onKeyDown={() => {}}
        role="presentation"
      >
        <button className="a all-articles-link" onClick={onDone}>
          <SVG src="/images/icons/arrow-left.svg" height={12} />
          Go back
        </button>
        <h1>{article.title}</h1>
        <h3>{article.subtitle}</h3>
        <div dangerouslySetInnerHTML={{ __html: articleBody }} />
        <br />
        <button className="primary" onClick={onDone}>
          Go back
        </button>
      </article>
    </>
  )
}
